/* src/styles.css */

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f5f5f5;
  }
  
  #root {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 8px;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .MuiButton-containedPrimary {
    background-color: #3f51b5;
  }
  
  .MuiButton-containedPrimary:hover {
    background-color: #303f9f;
  }
  
  .MuiTextField-root {
    margin-bottom: 10px;
  }
  
  .MuiIconButton-root {
    color: #3f51b5;
  }
  